import Vue from 'vue';
import Icon from '../components/Icon';
import styles from './showMessage.module.less';


/**
 * 
 * @param {*} comp 
 * @param {*} props 
 * @returns 获取某个组件渲染的dom根元素
 */
function getComponentRootDom(comp, props) {
    const vm = new Vue({
        render: (h) => h(comp, { props }),
    });
    vm.$mount();
    return vm.$el;
}


/**
 * 
 * @param {*} content 消息内容
 * @param {*} type    消息类型  info error success warn
 * @param {*} duration    消息显示多久后消失
 * @param {*} container   容器， 消息会显示到该容器的中间，若果不传，显示在页面中间
 */
export default function (options = {}) {
    const content = options.content || "";
    const type = options.type || "info";
    const duration = options.duration || 2000;
    const container = options.container || document.body;

    // 创建消息元素
    const div = document.createElement('div');
    const iconDom = getComponentRootDom(Icon, {
        type: type
    });
    div.innerHTML = `<span class="${styles.icon}">${iconDom.outerHTML}</span><div>${content}</div>`;

    // 设置样式
    // console.log(styles);
    const typeClassName = styles[`message-${type}`];  // 类型样式名
    div.className = `${styles.message} ${typeClassName}`;

    // 将div加入到容器中
    // 容器的position是否改动过
    if(options.container){
        if (getComputedStyle(container).position === 'static') {
            container.style.position = 'relative';
        }
    }
    
    container.appendChild(div);

    // console.log(div);

    // 强行渲染
    div.clientHeight;  // 导致强行渲染

    // 回归到正常居中的位置
    div.style.opacity = 1;
    div.style.transform = `translate(-50%, -50%)`;

    // 等一段时间消失
    setTimeout(() => {
        div.style.opacity = 0;
        div.style.transform = `translate(-50%, -50%) translateY(-25px)`;
        div.addEventListener('transitionend', function () {
            div.remove();
            // 运行回调函数
            options.callback && options.callback();
        }, { once: true });
    }, duration);
}

